@import '../global/colors.scss';
@import '../global/buttons.scss';
@import '../global/mixins.scss';
@import '../global/typography.scss';
.quick-add {
  display: flex;
  button {
    @extend .btn-text-primary;
    margin-top: 10px;
    font-size: 14px;
  }
  div {
    margin-right: 30px;
  }
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    font-size: 0.8em;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}

@page {
  margin: 15mm;
}
