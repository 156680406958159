@import '../global/colors.scss';
@import '../global/buttons.scss';
@import '../global/mixins.scss';
@import '../global/typography.scss';
.schedule {
  p {
    font-size: unset;
  }
  .rbc-toolbar-label {
    font-family: $roboto;
    font-size: 20px;
  }
  .rbc-header {
    font-family: $roboto;
    font-size: 16px;
    padding: 10px 0px;
  }
  .rbc-allday-cell {
    display: none;
  }
  .rbc-time-gutter,
  .rbc-time-header-gutter {
    width: 80px !important;
  }
  .rbc-time-header-content > .rbc-row.rbc-row-resource,
  .rbc-header {
    border-bottom: none;
  }
  .rbc-time-gutter .rbc-label {
    font-family: $roboto;
  }
  .rbc-time-content {
    border-top: 3px solid #ddd;
  }
  .rbc-day-slot {
    background-color: white;
  }
  .rbc-time-gutter .rbc-timeslot-group {
    padding: 5px;
  }
  .rbc-event {
    max-width: 300px;
  }
  .rbc-event-content {
    font-family: 'Times New Roman', Times, serif;
    font-size: 11px;
  }
  .rbc-current-time-indicator {
    height: 2px;
  }
}
