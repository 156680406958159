/* news-cycle-regular - latin */

@font-face {
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/news-cycle/news-cycle-v17-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../../fonts/news-cycle/news-cycle-v17-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/news-cycle/news-cycle-v17-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/news-cycle/news-cycle-v17-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../../fonts/news-cycle/news-cycle-v17-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../fonts/news-cycle/news-cycle-v17-latin-regular.svg#NewsCycle') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* sura-regular - latin */

@font-face {
  font-family: 'Sura';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/sura/sura-v13-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''), url('../../fonts/sura/sura-v13-latin-regular.eot') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/sura/sura-v13-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/sura/sura-v13-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../../fonts/sura/sura-v13-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/sura/sura-v13-latin-regular.svg#Sura') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* roboto-regular - latin */

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/roboto/roboto-v29-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../../fonts/roboto/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/roboto/roboto-v29-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../fonts/roboto/roboto-v29-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../../fonts/roboto/roboto-v29-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/roboto/roboto-v29-latin-regular.svg#Roboto')
      format('svg');
  /* Legacy iOS */
  font-display: swap;
}

$newsCycle: 'News Cycle';
$sura: 'Sura';
$roboto: 'Roboto';
%header-text {
  font-weight: bold;
  letter-spacing: 0.05em;
}

%button-text {
  @extend %header-text;
  font-size: 16px;
  line-height: 16px;
}

%message {
  font-size: 16px;
  font-family: $sura;
}

.roboto {
  font-family: $roboto;
}

.sura {
  font-family: $sura;
}

.news-cycle {
  font-family: $newsCycle;
}

.bold {
  font-weight: bolder;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}
