@import '../global/colors.scss';
@import '../global/mixins.scss';
@import '../global/typography.scss';
.pet-snapshot {
  @include box-shadow;
  background-color: $layoutWhite;
  margin-bottom: 24px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 5px;
  * {
    cursor: pointer;
  }
  &.content,
  .content {
    padding: 12px;
    @media screen and (min-width: 768px) {
      padding: 20px;
    }
    .quick-view-items {
      div {
        width: 150px;
      }
    }
  }
  .more-details {
    width: 40px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }
  &:hover:not(.pending) {
    border: 1px solid $darkBlue;
    .more-details {
      color: $layoutWhite;
      background-color: $darkBlue;
    }
  }
}

.pet-tabs {
  li {
    @extend %flex-centered;
    padding: 0px 10px;
    font-family: $roboto;
    color: $disabledDark;
    background-color: $subtle;
    border: 1px solid $disabledLight;
    cursor: pointer;
  }
  .selected {
    background-color: #157d8b;
    color: $layoutWhite;
    border: none;
  }
}

.pet-info {
  background-color: $layoutWhite;
  @include box-shadow;
  border-radius: 0px 5px 5px 5px;
  .attribute {
    display: flex;
    label {
      width: 150px;
      padding-bottom: 8px;
      font-size: 14px;
      font-family: $sura;
      font-weight: 700;
      color: $layoutBlack;
    }
    p {
      font-family: $roboto;
      font-size: 14px;
    }
  }
}
