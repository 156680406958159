@import '../global/colors.scss';
@import '../global/mixins.scss';
.auth-container {
  @extend %flex-centered;
  display: flex;
  flex-grow: 1;
  background-color: $layoutWhite;
  @media screen and (min-width: 768px) {
    background-color: $layoutGray;
  }
  form {
    padding: 20px 0px;
    @media screen and (min-width: 768px) {
      @include box-shadow;
      padding: 100px;
      background-color: $layoutWhite;
    }
    border-radius: 5px;
    img {
      width: 200px;
      margin-bottom: 30px;
      @media screen and (min-width: 768px) {
        width: 250px;
        margin-bottom: 40px;
      }
    }
    .form-control {
      border: 1px solid $interactiveGreen3;
    }
  }
}