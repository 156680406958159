@import '../global/colors.scss';
@import '../global/mixins.scss';
@import '../global/typography.scss';
.search-fields {
  padding: 30px;
  background-color: $layoutGray;
}

.search-results {
  @include box-shadow;
  flex-grow: 1;
  background-color: $layoutWhite;
}

.search-result {
  a {
    &:hover,
    &:visited {
      text-decoration: none;
      color: none;
    }
  }
  .client-result {
    @media screen and (min-width: 768px) {
      height: 60px;
    }
    padding: 20px;
    background-color: $layoutWhite;
    font-size: 16px;
    font-family: $roboto;
    line-height: 21px;
    :first-child {
      font-weight: 700;
    }
    &:hover {
      background-color: $layoutWhite;
      color: $interactiveGreen3;
    }
    &:active {
      background-color: $layoutWhite;
      border: 1px solid $interactiveGreen3;
    }
    span {
      display: inline-block;
      width: 50%;
      @media screen and (min-width: 768px) {
        width: 25%;
        font-size: 18px;
      }
    }
  }
  .pet-result {
    height: 40px;
    padding: 10px 30px 0px 30px;
    background-color: $layoutGray;
    color: black;
    font-size: 12px;
    font-family: $roboto;
    line-height: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    :first-child {
      font-weight: 700;
    }
    &:hover {
      background-color: $layoutGreen;
      color: black;
    }
    &:active {
      background-color: $layoutGray;
      border: 1px solid $layoutGreen;
    }
    span {
      width: 40%;
      @media screen and (min-width: 768px) {
        width: 15%;
      }
    }
  }
}
