button {
  @extend %button-text;
  @extend %flex-centered;
  height: 40px;
  background-color: transparent;
  box-shadow: none;
  border: none;
  font-size: 16px;
  &:disabled {
    color: $disabledLight;
  }
}

.btn-text-primary,
.btn-text-secondary,
.btn-text-error {
  height: auto;
  justify-content: start;
  &:hover:not(:disabled) {
    text-decoration: underline;
  }
  &:disabled {
    color: $disabledLight;
  }
}

.btn-text-primary {
  color: $attention;
  &:hover:not(:disabled) {
    color: $interactiveGreen3;
  }
}

.btn-text-secondary {
  color: $disabledDark;
  &:hover:not(:disabled) {
    color: $layoutBlack;
  }
}

.btn-text-error {
  color: $error;
  &:hover:not(:disabled) {
    color: $error;
  }
}

.btn-rounded-primary,
.btn-rounded-secondary {
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  &:disabled {
    color: #a8a8a8;
    background-color: $disabledBackground;
    border: 1px solid #a8a8a8;
  }
}

.btn-rounded-primary {
  color: $attention;
  border: 1px solid $attention;
  &:hover:not(:disabled) {
    color: $layoutWhite;
    background-color: $attention;
  }
}

.btn-rounded-secondary {
  color: $disabledDark;
  border: 1px solid $disabledDark;
  &:hover:not(:disabled) {
    color: $layoutWhite;
    background-color: $disabledDark;
  }
}

.btn-filled-primary,
.btn-filled-secondary,
.btn-filled-error {
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  @media screen and (min-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  &:disabled {
    color: $layoutWhite;
    background-color: $subtle;
  }
}

.btn-filled-primary {
  color: $layoutWhite;
  background-color: $interactiveGreen3;
  &:hover:not(:disabled) {
    background-color: $interactiveGreen1;
  }
}

.btn-filled-secondary {
  color: $disabledDark;
  border: 1px solid $disabledLight;
  &:hover:not(:disabled) {
    color: $layoutWhite;
    background-color: $disabledDark;
    border: 1px solid $disabledDark;
  }
}

.btn-filled-error {
  color: $layoutWhite;
  background-color: $error;
}